import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsConditions = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Terms &amp; Conditions" />

      <div className="container md:mx-auto mt-6">
        <h1 className="font-bold text-5xl text-center py-10">Terms &amp; Conditions</h1>
        <div className="static-content">
          <p>
            By visiting and using&nbsp;
            <a
              href="https://snorkout.com.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              snorkout.com
            </a>
            &nbsp;(hereinafter the “website”),&nbsp;
            <strong>
              you accept and agree to be bound by these Terms and Conditions
              including our&nbsp;Disclaimer&nbsp;and&nbsp;Privacy
              Policy&nbsp;posted on the website and incorporated herein by
              reference.
            </strong>
          </p>
          <p>
            The term “you” refers to anyone who uses, visits and/or views the
            website. Snorkout&nbsp;(“company”, “I”, “we” or “us”) reserves the
            right to amend or modify these terms and conditions in its sole
            discretion at any time without notice and by using the website, you
            accept those amendments.&nbsp; It is your responsibility to
            periodically check the website for updates.
          </p>
          <p>
            <strong>
              Your continued use of the website after posting of any changes to
              our Terms and Conditions constitutes your acceptance of those
              changes and updates. You must not access or use the website if you
              do not wish to be bound by these Terms and Conditions.
            </strong>
          </p>
          <h2 id="h-age-and-united-states-use-only">
            <strong>AGE AND UNITED STATES USE ONLY</strong>
          </h2>
          <p>
            All information and content on this website are intended for
            individuals over the age of 18. Children, as defined in
            our&nbsp;Privacy Policy, are prohibited from using this website. We
            do not envision offering products or services to individuals living
            in the European Union as outlined in the General Data Protection
            Regulation. Additionally, we make no representation that the
            information provided on the website including any products and/or
            services are available or appropriate for use in other locations
            including but not limited to the European Union as outlined in the
            General Data Protection Regulation.
          </p>
          <h2 id="h-privacy-policy">
            <strong>PRIVACY POLICY</strong>
          </h2>
          <p>
            We are dedicated to respecting the privacy of your personal
            information. Your acceptance of our Privacy Policy is expressly
            incorporated into these Terms and Conditions. Please review
            our&nbsp;Privacy Policy&nbsp;for more information.
          </p>
          <h2 id="h-disclaimer">
            <strong>DISCLAIMER</strong>
          </h2>
          <p>
            Your acceptance of our Disclaimer is expressly incorporated into
            these Terms and Conditions. Please review
            the&nbsp;Disclaimer&nbsp;for more information.
          </p>
          <h2 id="h-mandatory-arbitration-and-governing-law">
            <strong>MANDATORY ARBITRATION AND GOVERNING LAW</strong>
          </h2>
          <p>
            You expressly waive your right to bring any legal claims, now or in
            the future arising out of or related to the website and our
            products/services. In the event of any dispute, claim or controversy
            arising out of or relating to your use of this website, the terms
            and conditions shall be construed in accordance with the rules and
            regulations of the state of Texas and the United States.
          </p>
          <p>
            You agree to consent and submit to the jurisdiction of the state and
            federal courts located in Texas without regard to the principles of
            conflict of law or where the parties are located at the time a
            dispute arises.
          </p>
          <p>
            You agree to resolve any disputes or claims first through mandatory
            arbitration in the state of Texas and shall bear the full cost of
            arbitration as permitted by law. Your good faith participation in
            arbitration is a condition precedent to pursuing any other legal or
            equitable remedies available such as litigation or any other legal
            procedure. You also agree that in the event a legal claim is
            initiated after the required arbitration, the prevailing party shall
            be entitled to recover reasonable attorney’s fees and other costs
            associated with the legal action.
          </p>
          <h2 id="h-intellectual-property">
            <strong>INTELLECTUAL PROPERTY</strong>
          </h2>
          <p>
            All content on this website including but not limited to text,
            posts, logos, marks, graphics, files, materials, services, products,
            videos, audio, applications, computer code, designs, downloads and
            all other information here (collectively, the “Content”) is owned by
            us and is protected by copyright, trademark and other intellectual
            property and unfair competition laws with the exception of any
            content from others that we are lawfully permitted to use. &nbsp;You
            are granted a limited revocable license to print or download Content
            from the website for your own personal, non-commercial,
            non-transferrable, informational and educational use only while
            ensuring it’s not in violation of any copyright, trademark, and
            intellectual property or proprietary rights.
          </p>
          <p>
            You agree not to copy, duplicate, steal, modify, publish, display,
            distribute, reproduce, store, transmit, post, create derivative
            works, reverse engineer, sell, rent or license any part of the
            Content in any way to anyone, without our prior written
            consent.&nbsp; You agree to abide by the copyright, trademark laws
            and intellectual property rights and shall be solely responsible for
            any violations of these terms and conditions.
          </p>
          <h2 id="h-user-content-and-lawful-use-of-the-website">
            <strong>USER CONTENT AND LAWFUL USE OF THE WEBSITE</strong>
          </h2>
          <p>
            For any Content or information that you upload, display, post,
            transmit, send, email or submit to us on the website or on any of
            our social media sites, you warrant that you are the owner of that
            Content or have express permission from the owner of those
            intellectual property rights to use and distribute that Content to
            us.
          </p>
          <p>
            You grant us and/or our officers, employees, successors,
            shareholders, joint venture partners or anyone else working with us
            a royalty-free, perpetual, irrevocable, worldwide, non-exclusive
            right and license to identify you, publish, post, reformat, copy,
            distribute, display, edit, reproduce any Content provided by you on
            our website and on any of our social media sites for any purpose.
            You shall be solely liable for any damages resulting from any
            infringement of copyrights, trademark or other proprietary rights of
            any Content or information that you provide to us.
          </p>
          <p>
            You agree not to upload, display, post, transmit, distribute, send,
            email or submit to us on the website or on any of our social media
            sites any information or Content that is-
          </p>
          <p>(a) illegal, violates or infringes upon the rights of others,</p>
          <p>
            (b) defamatory, abusive, profane, hateful, vulgar, obscene,
            libelous, pornographic, threatening,
          </p>
          <p>
            (c) encourages or advocates conduct that would constitute a criminal
            offense, giving rise to civil liability or otherwise violate any
            law,
          </p>
          <p>
            (d) distribute material including but not limited to spyware,
            computer virus, any kind of malicious computer software or any other
            harmful information that is actionable by law,
          </p>
          <p>
            (e) any attempts to gain unauthorized access to any portion or
            feature of the website, and
          </p>
          <p>
            (f) send unsolicited or unauthorized material or cause disruption in
            the operation of the website. You agree to use the website for
            lawful purposes only and shall be liable for damages resulting from
            the violation of any provision contained in these Terms and
            Conditions.
          </p>
          <h2 id="h-third-party-links">
            <strong>THIRD-PARTY LINKS</strong>
          </h2>
          <p>
            The website may contain links to third-party websites or resources
            for your convenience. We may serve as an affiliate for some of these
            third-party websites by offering or advertising their products or
            services on the website; however, we do not own or control these
            third-party websites. Once you click on a third-party link and leave
            this website, you are no longer bound by our terms and conditions.
          </p>
          <p>
            You agree that we are not responsible or liable for the accuracy,
            content or any information presented on these third-party websites.
            You assume all risks for using these third-party websites or
            resources and any transactions between you and these third-party
            websites are strictly between you and the third party. We shall not
            be liable for any damages resulting from your use of these
            third-party websites or resources.
          </p>
          <h2 id="h-use-of-our-paid-and-free-products">
            <strong>USE OF OUR PAID AND FREE PRODUCTS</strong>
          </h2>
          <p>
            We may offer free products for you to download and also sell paid
            courses, programs, physical or digital products and any other
            related materials (collectively, “products”) on this website. All
            our products and/or services including all content are protected by
            copyright pursuant to the US and international copyright laws. You
            are granted a limited revocable license to print or download Content
            from our digital products for your own personal, non-commercial,
            non-transferrable, informational and educational use only while
            ensuring it’s not in violation of any copyright, trademark, and
            intellectual property or proprietary rights. Copying or storing our
            content for other than personal use is expressly prohibited without
            our prior written consent.
          </p>
          <p>
            You acknowledge and agree that you have no right to share, modify,
            sell, edit, copy, reproduce, create derivative works of, reverse
            engineer, enhance or in any exploit our products. You cannot sell or
            redistribute any of our products, whether free or paid ones, without
            our express written consent.&nbsp; You agree to abide by the
            copyright, trademark laws and intellectual property rights and shall
            be solely responsible for any violations of these terms and
            conditions.
          </p>
          <h2 id="h-termination">
            <strong>TERMINATION</strong>
          </h2>
          <p>
            We reserve the right in our sole discretion to refuse, remove,
            restrict your access, revoke and terminate your use of our website
            including any or all Content published by you or us at any time for
            any reason, without notice.
          </p>
          <h2 id="h-no-refunds">
            <strong>NO REFUNDS</strong>
          </h2>
          <p>
            All sales of products and/or services on this website are final. No
            refunds will be issued. We truly believe in giving more than
            receiving and each of our products and services is designed by
            keeping this core principle in mind. The prices are intentionally
            kept reasonably low in price as compared to market value to give you
            the tools and information you need at an affordable price.
          </p>
          <h2 id="h-no-warranties">
            <strong>NO WARRANTIES</strong>
          </h2>
          <p>
            ALL CONTENT, INFORMATION, PRODUCTS AND/OR SERVICES ON THE WEBSITE
            ARE “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATIONS OR
            WARRANTIES OF ANY KIND INCLUDING THE WARRANTIES OF MERCHANTABILITY
            OR FITNESS FOR ANY PURPOSE, EXPRESS OR IMPLIED TO THE FULL EXTENT
            PERMISSIBLE BY LAW. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
            AS TO THE CONTENT, INFORMATION, MATERIALS, PRODUCTS AND/OR SERVICES
            PROVIDED ON THIS WEBSITE. COMPANY MAKES NO WARRANTIES THAT THE
            WEBSITE WILL PERFORM OR OPERATE TO MEET YOUR REQUIREMENTS OR THAT
            THE INFORMATION PRESENTED HERE WILL BE COMPLETE, CURRENT OR
            ERROR-FREE. COMPANY DISCLAIMS ALL WARRANTIES, IMPLIED AND EXPRESS
            FOR ANY PURPOSE TO THE FULL EXTENT PERMITTED BY LAW.
          </p>
          <h2 id="h-limitation-of-liability">
            <strong>LIMITATION OF LIABILITY</strong>
          </h2>
          <p>
            You agree that under no circumstances, we and/or our officers,
            employees, successors, shareholders, joint venture partners or
            anyone else working with us shall be liable for any direct,
            indirect, incidental, consequential, equitable, special, punitive,
            exemplary or any other damages resulting from your use of this
            website including but not limited to all the content, information,
            products, services and graphics presented here.
          </p>
          <p>
            You expressly agree that your use of the website is at your sole
            risk and that you are solely responsible for the accuracy of the
            personal and any information you provide, the outcome of your
            actions, personal and business results, and for all other use in
            connection with the website.
          </p>
          <p>
            You also expressly agree that we and/or our officers, employees,
            successors, shareholders, joint venture partners or anyone else
            working with us shall not be liable to you for any damages resulting
            from 1) any errors or omissions on the website, delay or denial of
            any products or services, failure of performance of any kind,
            interruption in the operation and your use of the website, website
            attacks including computer virus, hacking of information, and any
            other system failures; 2) any loss of income, use, data, revenue,
            profits, business or any goodwill related to the website; 3) any
            theft or unauthorized access by third party of your information from
            the website regardless of our negligence; and 4) any use or misuse
            of the information, products and/or services offered here.
          </p>
          <p>
            This limitation of liability shall apply whether such liability
            arises from negligence, breach of contract, tort or any other legal
            theory of liability. You agree that we provide no express or implied
            guarantees to you for the content presented here, and you accept
            that no particular results are being promised to you here.
          </p>
          <h2 id="h-indemnification">
            <strong>INDEMNIFICATION</strong>
          </h2>
          <p>
            You agree to indemnify and hold the Company and/or its officers,
            employees, successors, shareholders, joint venture partners or
            anyone else working with us harmless from all losses, claims,
            damages, demands, actions, suits, proceedings or judgments,
            including costs, expenses and reasonable attorneys’ fees
            (“Liabilities”) assessed against or otherwise incurred by you
            arising, in whole or in part, from: (a) actions or omissions,
            whether done negligently or otherwise, by you, your agents,
            directors, officers, employees or representatives; (b) all your
            actions and use of the website including purchasing products and
            services; (c) violation of any laws, rules, regulations or
            ordinances by you; or (d) violation of any terms and conditions of
            this website by you or anyone related to you; e) infringement by you
            or any other user of your account of any intellectual property or
            other rights of anyone. The Company will notify you promptly of any
            such claims or liability and reserves the right to defend such
            claim, liability or damage at your expense. You shall fully
            cooperate and provide assistance to us if requested, without any
            cost, to defend any such claims.
          </p>
          <h2 id="h-entire-agreement">
            <strong>ENTIRE AGREEMENT</strong>
          </h2>
          <p>
            These Terms and Conditions along with our&nbsp;Privacy
            Policy&nbsp;and&nbsp;Disclaimer&nbsp;constitute the entire agreement
            between you and us with respect to this website. It supersedes all
            prior or contemporaneous communications, discussions, negotiations
            or proposals we may have had with you whether electronic, oral or
            written.
          </p>
          <p>
            A printed version of this entire agreement including
            the&nbsp;Privacy Policy&nbsp;and&nbsp;Disclaimer&nbsp;and of any
            notice given in electronic form shall be admissible in judicial or
            administrative proceedings with respect to this website to the same
            extent and given the same effect as other business contracts and
            documents kept and maintained in printed form.
          </p>
          <h2 id="h-severability">
            <strong>SEVERABILITY</strong>
          </h2>
          <p>
            If any provision in these Terms and Conditions is deemed by a court,
            regulatory authority or other public or private tribunal of
            competent jurisdiction to be invalid or unenforceable, such
            provision is deemed to have been omitted from this Agreement. The
            remainder of this Agreement remains in full force and effect, and is
            modified to any extent necessary to give such force and effect to
            the remaining provisions, but only to such extent.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default TermsConditions

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
